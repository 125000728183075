<template>
	<div class="header">
		<div class="left-box">
			<router-link to="/">
				<img src="@/assets/logo.png" alt="瑞蚁云表单">
			</router-link>
			<el-popover placement="bottom" width="400" trigger="hover" popper-class="version-popover">
				<div class="version-box">
					<div class="version-head">
						<h4>云表单{{version.name}}</h4>
						<a href="https://www.ruiyi126.com/pd/762_1121_1122/" target="_blank" class="fr">更多版本详情&gt;</a>
					</div>
					<div class="version-main">
						<h5><span>付费版特殊功能</span></h5>
						<ul>
							<li>
								<img src="/form/ucenter/img/icons/version_ico01.png" alt="">
								<dl>
									<dt>超高答卷总数上限</dt>
									<dd>单份问卷可以回收多达20万份答卷</dd>
								</dl>
							</li>
							<li>
								<img src="/form/ucenter/img/icons/version_ico02.png" alt="">
								<dl>
									<dt>多用户一起使用</dt>
									<dd>支持添加多个子账户，和同事共同使用</dd>
								</dl>
							</li>
							<li>
								<img src="/form/ucenter/img/icons/version_ico03.png" alt="">
								<dl>
									<dt>答卷批量导出</dt>
									<dd>允许答卷数据批量导出</dd>
								</dl>
							</li>
							<li>
								<img src="/form/ucenter/img/icons/version_ico04.png" alt="">
								<dl>
									<dt>个性化页面</dt>
									<dd>个怀化定制，体现自有品牌形象</dd>
								</dl>
							</li>
							<li>
								<img src="/form/ucenter/img/icons/version_ico05.png" alt="">
								<dl>
									<dt>高并发访问</dt>
									<dd>默认支持多人同时在线答题</dd>
								</dl>
							</li>
							<li>
								<img src="/form/ucenter/img/icons/version_ico06.png" alt="">
								<dl>
									<dt>1V1 服务支持</dt>
									<dd>服务顾问全流程跟进服务</dd>
								</dl>
							</li>
						</ul>
						<a href="https://www.ruiyi126.com/pd/762_1121_1122/" class="version-btn" target="_blank">立即升级</a>
					</div>
				</div>
				<el-button slot="reference" type="primary" round size="small">{{version.name}}</el-button>
			</el-popover>
			<!-- <div class="c-version fl">
				<div class="version-active">免费版</div>
			
			</div> -->
		</div>
		<div class="right-box">
			<el-popover placement="bottom-end" width="330" trigger="hover" popper-class="notice-popover" v-if="noticeList && noticeList.length > 0">
				<div class="notice-info">
					<div class="notice-top">新消息</div>
					<ul>
						<li v-for="item in noticeList" :key="item.id"><a href="javascript:void(0);" @click="showNotice(item.id);">[重要公告]{{item.name}}</a></li>
					</ul>
					<router-link class="notice-link" to="/notice">查看更多&gt;&gt;</router-link>
				</div>
				<div class="user-notice" slot="reference"><el-badge is-dot><div class="notice-ico"></div></el-badge></div>
			</el-popover>
			<el-popover placement="bottom-end" width="213" trigger="hover" popper-class="user-popover">
				<div class="user-info">
					<div class="info-inner">
						<div class="user-name">
							<div class="user-head fl"><img :src="user.headPortrait?user.headPortrait:'@/assets/images/ruiyi.png'" alt=""></div>
							<span>{{user.name}}</span>
						</div>
						<ul>
							<li><a href="https://bd.ruiyi126.com/"><i class="i-ico i-my"></i><span>首页</span></a></li>
							<li><router-link to="/linkman"><i class="i-ico i-linkman"></i><span>联系人</span></router-link></li>
							<li><router-link to="/emailSet"><i class="i-ico i-mailset"></i><span>邮箱设置</span></router-link></li>
						</ul>
						<div class="user-exit"><a href="javascript:void(0);" @click="logout"><i class="i-ico i-exit"></i><span>退出</span></a></div>
					</div>
				</div>
				<div class="user-head" slot="reference"><el-avatar :size="36" :src="user.url"></el-avatar></div>
			</el-popover>
		</div>
		<el-dialog title="消息详情" :visible.sync="dialogVisible" width="600px" :append-to-body="true">
			<div class="notice-body">
				<h4 class="notice-title">{{detail.name}}</h4>
				<p class="notice-time">{{detail.createDate}}</p>
				<div class="notice-content">{{detail.content}}</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="cancel">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { get,post} from "/src/services/ajax_ucenter.js";
export default{
	name: "ucenterHeader",
	props: {
	},
	data(){
		return {
			noticeList: [],
			user:{},
			version:{},
			dialogVisible:false,
			detail:{}
		}
	},
	created(){
		this.getInfo();
	},
	methods: {
		getNotice(){
			const _this = this;
			get('/formadmin/get_ucenter_msg.jhtml ',{}).then(res => {
				if(res.code == 200){
					_this.noticeList = res.data.dataList;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 查看
		showNotice(id){
			const _this = this;
			post('/formadmin/ucenter_msg_detail.jhtml',{id}).then(res => {
				if(res.code == 200){
					_this.detail = res.data;
					_this.dialogVisible = true;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 取消
		cancel(){
			const _this = this;
			_this.dialogVisible = false;
		},
		getInfo(){
			const _this = this;
			get('/formadmin/index.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.user = res.data.user;
					_this.version = res.data.version;
					// sessionStorage.setItem('userId',_this.user._id);
					this.getNotice();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 退出登录
		logout(){
			const _this = this;
			get('/formadmin/logout.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.$router.push("/login")
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		}
	}
};
</script>
<style lang="less">
.el-popover.notice-popover,.el-popover.version-popover{
	padding:0;
	border-radius: 0;
	margin-top: 5px;
	.popper__arrow::after{
		border-bottom-color: #f5f5f5;
	}
}
.el-popover.user-popover{
	padding:0;
	border-radius: 0;
	margin-top: 0;
	.popper__arrow::after{
		border-bottom-color: #00a2ff;
	}
}	
</style>
<style lang="less" scoped>
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding: 0 20px;
	border-bottom: 1px solid #eee;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: relative;
	z-index: 999;
	.left-box{
		display: flex;
		align-items: center;
		.el-button{
			margin-left: 30px;
		}
	}
	.right-box{
		display: flex;
		align-items: center;
	}
	::v-deep .el-popover__reference-wrapper{
		padding:0;
		.user-notice{
			padding:5px 30px;
			margin-right: 30px;
			border-right: 1px solid #ddd;
			position: relative;
			.notice-ico{
				background: url(../assets/images/notice_ico.png) no-repeat;
				width:19px;height: 21px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		.user-head{}
	} 
}
	.el-popover{
		.notice-info{
			.notice-top{
				padding: 0 20px;
				line-height: 42px;
				border-bottom: 1px solid #eee;
				background: #f5f5f5;
			}
			.notice-link{
				line-height: 42px;
				border-top: 1px solid #eee;
				color:#666;
				display: block;
				text-align: center;
				background: #f5f5f5;
				&:hover{
					color:#0cf;
				}
			}
			ul{
				margin:13px 20px;
				line-height:34px;
				max-height: 170px;
				overflow: hidden;
				li{
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
		.user-info{
			.user-name{
				padding:14px;
				-size: 14px;
				line-height: 36px;
				color:#fff;
				background: url(../assets/images/head_popbg.png) no-repeat center;
			}
			.user-exit{
				padding:8px 0 1px 4px;
				font-size: 14px;
				line-height: 30px;
				margin:0 16px;
				border-top: 1px solid #e5e5e5;
			}
			a{
				display: block;
				height: 30px;
				line-height: 30px;
				color:#888;
			}
			.i-ico{
				width:15px;
				height:15px;
			}
			span{
				vertical-align: middle;
				padding-left:8px;
			}
			ul{
				padding:4px 0;
				li{
					padding:5px 0;
					a{
						padding-left:20px;
						&:hover{
							background: #f5f6fa;
							color:#0cf;
						}
					}
					.i-my{background-position:-30px 0;}
					&:hover .i-my{background-position:-30px -30px;}
					.i-linkman{background-position:-60px 1px;}
					&:hover .i-linkman{background-position:-60px -29px;}
					.i-myfavorite{background-position:-90px 2px;}
					&:hover .i-myfavorite{background-position:-90px -28px;}
					.i-mailset{background-position: 0 -247px;}
					&:hover .i-mailset{background-position: 0 -272px;}
					.i-exit{background-position:-120px 2px;}
				}
			}
			.user-exit:hover .i-exit{background-position:-120px -28px;}
			.user-exit a:hover{color:#0cf;}
		}
		/* 版本升级 */
		.version-box{
			// width:568px;
			// background: #fff;
			// position: absolute;
			// top:50px;left:-14px;
			// box-shadow: 0 8px 10px 0 rgba(0,0,0,.28);
			.version-head{
				background: #f8f8f8;
				padding: 10px 28px;
				line-height: 32px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				h4{
					font-size: 16px;
					font-weight: bold;
					text-align: left;
				}
				a{
					font-size: 13px;color: #999;
				}
			}
			.version-main{
				padding: 26px 35px;
				text-align: center;
				h5{
					position: relative;
					margin-bottom: 6px;
					&:before{
						content: "";
						display: block;
						width: 100%;
						height: 12px;
						border-bottom: 1px solid #ddd;
						position: absolute;
						left: 0;
						top:0;
					}
					span{
						padding: 0 8px;
						background: #fff;
						line-height: 24px;
						position: relative;
						z-index: 1;
					}
				}
				ul{
					display: flex;
					flex-wrap: wrap;
					li{
						width:50%;
						padding: 9px 0;
						display: flex;
						align-items: start;
						dl{
							flex: 1;
							text-align: left;
							margin-left: 10px;
							dt{
								line-height: 20px;
								font-size: 14px;
							}
							dd{
								line-height: 20px;
								color:#999;
								font-size: 12px;
								margin-top: 4px;
							}
						}
					}
				}
				.version-btn{
					background: #ff5e1e;
					line-height: 30px;
					border:1px solid #ff5e1e;
					color:#fff;
					border-radius: 4px;
					display: block;
					margin-top: 12px;
					&:hover{background: none;color:#ff5e1e;;}
				}
			}
		}
	}
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			height: 400px;
			overflow-y: auto;
			.notice-body{
				height: 100%;
				overflow: auto;
				padding:0;
				box-sizing: border-box;
				.notice-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 1.8;
					text-align: center;
				}
				.notice-time{
					color:#999;
					text-align: center;
					line-height: 1.5;
					font-size: 13px;
				}
			}
		}
	}
</style>