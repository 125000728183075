<template>
    <div class="left_nav">
        <div class="nav_list">
            <router-link class="item" to="/">
                <i class="nav-ico nav-01"></i>
                <span class="txt">创建表单</span>
            </router-link>
            <router-link class="item" to="/model">
                <i class="nav-ico nav-02"></i>
                <span class="txt">模板中心</span>
            </router-link>
            <router-link class="item" to="/collect">
                <i class="nav-ico nav-03"></i>
                <span class="txt">我的收藏</span>
            </router-link>
            <router-link class="item" to="/myForm">
                <i class="nav-ico nav-04"></i>
                <span class="txt">我的表单</span>
            </router-link>
            <router-link class="item" to="/answer">
                <i class="nav-ico nav-05"></i>
                <span class="txt">我的答题</span>
            </router-link>
            <router-link class="item" to="/prize">
                <i class="nav-ico nav-06"></i>
                <span class="txt">奖品管理</span>
            </router-link>
            <router-link class="item" to="/user">
                <i class="nav-ico nav-07"></i>
                <span class="txt">用户管理</span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "ucenterLeftNav",
    props: {},
    data(){
		return {
            formType:'',
		}
	},
    watch:{
    }
};
</script>
<style lang="less" scoped>
.left_nav {
    .nav_list {
        width: 249px;
        height: 100%;
        padding: 32px 0 0;
        background-color: #fff;
        border-right: 1px solid #eee;
        overflow-y: auto;
        .item {
            display: flex;
            align-items: center;
            padding: 0 0 0 65px;
            margin-bottom: 17px;
            line-height: 40px;
            .nav-ico{
                background: url(../assets/images/left_nav.png) no-repeat;
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
            }
            .nav-01{background-position: 0 2px;}
            .nav-02{background-position: 0 -27px;}
            .nav-03{background-position: 0 -56px;}
            .nav-04{background-position: 0 -88px;}
            .nav-05{background-position: 0 -118px;}
            .nav-06{background-position: 0 -148px;}
            .nav-07{background-position: 0 -180px;}
            .nav-08{background-position: 0 -210px;}
            .txt {
                font-size: 14px;
                padding-left: 15px;
            }
            &:hover {
                .nav-01{background-position: -30px 2px;}
                .nav-02{background-position: -30px -27px;}
                .nav-03{background-position: -30px -56px;}
                .nav-04{background-position: -30px -88px;}
                .nav-05{background-position: -30px -118px;}
                .nav-06{background-position: -30px -148px;}
                .nav-07{background-position: -30px -180px;}
                .nav-08{background-position: -30px -210px;}
                .txt {
                    color: var(--theme-color);
                }
            }
        }
        .router-link-exact-active {
            background: #f6f7f9;
            .nav-01{background-position: -30px 2px;}
            .nav-02{background-position: -30px -27px;}
            .nav-03{background-position: -30px -56px;}
            .nav-04{background-position: -30px -88px;}
            .nav-05{background-position: -30px -118px;}
            .nav-06{background-position: -30px -148px;}
            .nav-07{background-position: -30px -180px;}
            .nav-08{background-position: -30px -210px;}
            .txt {
                color: var(--theme-color);
            }
        }
    }
}
</style>